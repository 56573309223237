.collapsibleContent {
  overflow: hidden;
  transition-duration: 0.5s;
  transition-property: height;
  will-change: height;

  &.slow {
    transition-duration: 0.7s;
  }

  &.fast {
    transition-duration: 0.2s;
  }

  &.collapsed {
    height: 0;
  }
}
