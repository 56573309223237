@import "storefront/components/styles/pxToRem.module.scss";
@import "mixins/media-queries/index";

.homepageV2 {
  margin-bottom: pxToRem(24);

  @include larger-than-tablet {
    margin-bottom: pxToRem(60);
  }

  > :global(.Homepage--Modules) {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: pxToRem(24);
    width: 100%;
    padding: 0 calc((100vw - pxToRem(390)) / 2);

    @include larger-than-tablet {
      flex-direction: row;
      align-items: start;
      justify-content: center;
      flex-wrap: wrap;
      gap: pxToRem(60) pxToRem(40);
      padding: 0 calc((100vw - pxToRem(786)) / 2);
    }

    @include larger-than(1200px) {
      padding: 0 calc((100% - pxToRem(1200)) / 2);
    }

    > :global(.CategoryGridModule) {
      margin-bottom: 0;
    }
  }
}
