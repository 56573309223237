@import "../styles/pxToRem.module.scss";
@import "mixins/media-queries";

.headline {
  font-weight: var(--font-weight--semibold);
  font-size: pxToRem(16);
  line-height: 1.3;

  @include larger-than-phone {
    font-weight: var(--font-weight--heavy);
  }
}
